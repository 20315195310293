import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDistricts(ctx, params) {
      return useJwt.getDistrict(params).then(response => response);
    },
    createDistrict(ctx, data) {
      return useJwt.createFloor(data).then(response => response);
    },
    updateDistrict(ctx, data) {
      return useJwt.updateFloor(data).then(response => response);
    },
    deleteDistricts(ctx, data) {
      return useJwt.deleteFloors(data).then(response => response);
    },
  },
};
