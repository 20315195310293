import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import districtStoreModule from '../districtStoreModule';

export default function useDistrictList() {
  const STORE_MODULE_NAME = 'district';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, districtStoreModule);
  }

  const toastification = toast();

  // add / edit
  const blankItem = {
    id: '',
    name: '',
    prefix: '',
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'Thao tác',
      field: 'action',
      sortable: false,
      width: '100px',
      hidden: true,
    },
    {
      label: 'Mã',
      field: 'code',
    },
    {
      label: 'Phân loại',
      field: 'prefix',
    },
    {
      label: 'Tên',
      field: 'name',
    },

  ];
  const rows = ref([]);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 100,
  });

  //   API Call

  const fetchDistricts = () => {
    store
      .dispatch('district/fetchDistricts', serverParams.value)
      .then(response => {
        totalRecords.value = response.totalRecords;
        rows.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };
  const refetchDistricts = () => {
    fetchDistricts();
  };

  const deleteDistricts = districts => {
    store
      .dispatch('district/deleteDistricts', {
        ids: districts.map(_obj => _obj.id),
      })
      .then(() => {
        fetchDistricts();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const createDistrict = () => {
    store
      .dispatch('district/createDistrict', {
        name: item.value.name,
      })
      .then(() => {
        fetchDistricts();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchDistricts();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = data => {
    item.value = data;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  return {
    item,
    resetItem,

    columns,
    rows,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    refetchDistricts,
    fetchDistricts,
    deleteDistricts,
    createDistrict,

    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onEditItem,
  };
}
